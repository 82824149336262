<template>
  <form
    ref="form"
    class="form form--availability js-charterForm"
    :class="[{'loading' : loading}]"
    @submit.prevent="submitHandler"
  >
    <div class="form__left">
      <div class="form__labelGroup form__labelGroup--text">
        <label class="form__label form__label--select">
          <div class="form__caption">Aircraft number</div>
          <div class="form__field-wrapper">
            <div class="selectContainer js-select-uploads"></div>
            <a-select
              v-if="isDetail == false"
              class="form__select"
              :getPopupContainer="selectContainer"
              @change="selectChange"
              :defaultValue="'Choose from list'"
              :disabled="loading"
              style="width:100%;"
            >
              <a-select-option
                v-for="(item, index) of fleet"
                :key="index"
                :value="item.tailNumber"
              >
                {{item.tailNumber}}
              </a-select-option>
            </a-select>
            <a-select
              v-else-if="aircraft"
              class="form__select"
              :getPopupContainer="selectContainer"
              @change="selectChange"
              :defaultValue="aircraft.aircraftNumber"
              :disabled="loading"
              style="width:100%;"
            >
              <a-select-option
                v-for="(item, index) of fleet"
                :key="index"
                :value="item.tailNumber"
              >
                {{item.tailNumber}}
              </a-select-option>
            </a-select>
            <span
              v-if="!aircraft && isNotReadyToSubmit == true"
              class="form__field-invalid form__field-invalid--right-side"
              style="color: #000"
            >
                  This field is required
                </span>
<!--            <input-->
<!--              v-else-if="aircraft"-->
<!--              class="form__field"-->
<!--              type="text"-->
<!--              v-model="aircraft.aircraftNumber"-->
<!--              autocomplete="off"-->
<!--            />-->
          </div>
        </label>
        <label class="form__label form__label--number">
          <div class="form__caption">Departure Re-routing radius:</div>
          <div class="form__field-wrapper">
            <input
              class="form__field js-numberOnly"
              type="text"
              v-model="departureReRouting"
              placeholder="Enter number here"
              autocomplete="off"
              :disabled="loading"
            />
            <span
              v-if="!departureReRouting && isNotReadyToSubmit == true"
              class="form__field-invalid form__field-invalid--right-side"
              style="color: #000"
            >
                  This field is required
                </span>
          </div>
        </label>
        <label class="form__label form__label--number">
          <div class="form__caption">Arrival Re-routing radius:</div>
          <div class="form__field-wrapper">
            <input
              class="form__field js-numberOnly"
              type="text"
              v-model="arrivalReRouting"
              placeholder="Enter number here"
              autocomplete="off"
              :disabled="loading"
            />
            <span
              v-if="!arrivalReRouting && isNotReadyToSubmit == true"
              class="form__field-invalid form__field-invalid--right-side"
              style="color: #000"
            >
                  This field is required
                </span>
          </div>
        </label>
      </div>

      <div class="form__labelGroup form__labelGroup--locations">
        <div
          class="form__label form__label--text js-list-locations"
          :class="{'limit' : departuresCount.length === departuresLimit}"
        >
          <div class="form__caption">
            <span class="icon"><SvgIcon name="departures"/></span>
            <span class="text text--desktop">Potential departure airports and locations:</span>
            <span class="text text--mobile">Departure address:</span>
          </div>
          <div class="form__field-wrapper js-wrapper-locations">
            <label
              class="js-item-locations"
              v-for="(item, id) of departuresCount"
              :key="id"
            >
              <div v-if="departuresCount.length > 1" class="form__remove js-remove-locations" @click="removeField(id, 'departure')">
                <SvgIcon name="close"/>
              </div>
              <input
                class="form__field js-typeahead"
                type="text"
                v-model="item.value"
                placeholder="Enter here"
                autocomplete="off"
                :disabled="loading"
                @input="searchLocations('departure', item.value)"
                @focus="setFocusedPotentialLocation(id)"
              />
              <span
                v-if="!item.value && isNotReadyToSubmit == true"
                class="form__field-invalid form__field-invalid--right-side"
                style="color: #000"
              >
                  This field is required
                </span>
            </label>
            <div
              class="typeahead-menu typeahead-empty"
              :class="{'typeahead-open' : departureItems.length > 0}"
            >
              <div class="typeahead-dataset typeahead-dataset-types">
                <div
                  class="typeahead-suggestion typeahead-selectable"
                  v-for="item in departureItems"
                  :key="item.id"
                  @click="selectPotentialLocation('departure', item)"
                >{{item.name}}</div>
              </div>
            </div>
          </div>
          <div class="form__add js-add-locations" @click="addFields('departure')">
            <div class="form__add-caption">Added max number of departure</div>
            <div class="form__add-icon">
              <SvgIcon name="plus"/>
            </div>
          </div>
        </div>
        <div
          class="form__label form__label--text js-list-locations"
          :class="{'limit' : landingCount.length === landingLimit}"
        >
          <div class="form__caption">
            <span class="icon"><SvgIcon name="arrival"/></span>
            <span class="text text--desktop">Potential landing airports and locations:</span>
            <span class="text text--mobile">Arrival address:</span>
          </div>
          <div class="form__field-wrapper js-wrapper-locations">
            <label
              class="js-item-locations"
              v-for="(item, id) of landingCount"
              :key="id"
            >
              <div v-if="landingCount.length > 1" class="form__remove js-remove-locations" @click="removeField(id, 'landing')">
                <SvgIcon name="close"/>
              </div>
              <input
                class="form__field js-typeahead"
                type="text"
                v-model="item.value"
                placeholder="Enter here"
                autocomplete="off"
                :disabled="loading"
                @input="searchLocations('landing', item.value)"
                @focus="setFocusedPotentialLocation(id)"
              />
              <span
                v-if="!item.value && isNotReadyToSubmit == true"
                class="form__field-invalid form__field-invalid--right-side"
                style="color: #000"
              >
                  This field is required
                </span>
            </label>
            <div
              class="typeahead-menu typeahead-empty"
              :class="{'typeahead-open' : landingItems.length > 0}"
            >
              <div class="typeahead-dataset typeahead-dataset-types">
                <div
                  class="typeahead-suggestion typeahead-selectable"
                  v-for="item in landingItems"
                  :key="item.id"
                  @click="selectPotentialLocation('landing', item)"
                >{{item.name}}</div>
              </div>
            </div>
          </div>
          <div class="form__add js-add-locations" @click="addFields('landing')">
            <div class="form__add-caption">Added max number of landing</div>
            <div class="form__add-icon">
              <SvgIcon name="plus"/>
            </div>
          </div>
        </div>
      </div>

      <div class="form__labelGroup form__labelGroup--date">
        <div class="form__title">Available dates:</div>
        <div
          class="form__label form__label--date js-list-dates"
          :class="{'limit' : dateFormCount.length === datesLimit}"
        >
          <div class="form__caption">
            <span class="icon"><SvgIcon name="calendar"/></span>
            <span class="text">From-To:</span>
          </div>
          <div class="form__field-list">
            <div
              class="form__field-wrapper js-wrapper-dates"
              v-for="(item, id) of dateFormCount"
              :key="id"
            >
              <label
                class="js-item-dates form__label--date-time"
                :class="{'form__label--date-time-selected': item.value && item.value.length > 0}"
              >
                <a-range-picker
                  format="MM/DD/YYYY"
                  :placeholder="['click to change', '']"
                  :separator="item.value && item.value.length > 0 ? ' - ' : ''"
                  v-model="item.value"
                  :getCalendarContainer="getPickerContainer"
                  :disabled="loading"
                />
                <span
                  v-if="!item.value && isNotReadyToSubmit == true"
                  class="form__field-invalid form__field-invalid--right-side"
                  style="color: #000"
                >
                  This field is required
                </span>
              </label>
              <div class="form__remove js-remove-dates" @click="removeField(id, 'dates')">
                <SvgIcon name="close"/>
              </div>
            </div>
          </div>
          <div class="form__add js-add-locations" @click="addFields('dates')">
            <div class="form__add-caption">Added max number of available dates</div>
            <div class="form__add-icon">
              <SvgIcon name="plus"/>
            </div>
          </div>
        </div>
      </div>

      <div class="form__labelGroup form__labelGroup--price">
        <div class="form__title">Price per hour / hourly rate:</div>
        <label class="form__label form__label--price price">
          <div class="form__field-wrapper">
            <!-- <input
              class="form__field"
              type="text"
              v-model="pricePerHour"
              v-money="money"
              placeholder="Enter cost here in $"
              autocomplete="off"
              :disabled="loading"
            /> -->
            <input
              class="form__field"
              type="text"
              v-model="pricePerHour"
              placeholder="Enter cost here in $"
              autocomplete="off"
              :disabled="loading"
            />
            <span
              v-if="!pricePerHour && isNotReadyToSubmit == true"
              class="form__field-invalid form__field-invalid--right-side"
              style="color: #000"
            >
                  This field is required
            </span>
          </div>
        </label>
        <label class="form__label form__label--price price">
          <div class="form__caption">
            <span class="icon"><SvgIcon name="triangle"/></span>
            <span class="text">Minimum acceptable cost per charter trip</span>
          </div>
          <div class="form__field-wrapper">
            <!-- <input
              class="form__field"
              type="text"
              v-model="minAcceptCost"
              v-money="money"
              placeholder="Enter cost here in $"
              autocomplete="off"
              :disabled="loading"
            /> -->
            <input
              class="form__field"
              type="text"
              v-model="minAcceptCost"
              placeholder="Enter cost here in $"
              autocomplete="off"
              :disabled="loading"
            />
            <span
              v-if="!minAcceptCost && isNotReadyToSubmit == true"
              class="form__field-invalid form__field-invalid--right-side"
              style="color: #000"
            >
                  This field is required
            </span>
          </div>
        </label>
        <span
          v-if="isNotReadyToSubmit == true"
          class="form__field-invalid"
        >
              Please fill out all fields.
        </span>
        <div
          class="form__label form__label--button"
        >
          <button class="form__button" :disabled="loading">
            <Spinner />
            <span class="text">Save Availability</span>
          </button>
        </div>
        <div
          v-if="isDetail"
          class="form__label form__label--button"
        >
          <button
            class="form__button"
            type="button"
            :disabled="loading"
            @click="deleteAvailability"
          >
            <Spinner />
            <span class="text">Remove Availability</span>
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
  import { aircraftsAPI } from '@/api/aircrafts'
  import { locationsAPI } from '@/api/locations'
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon'
  import Spinner from '@/components/common/Spinner/Spinner'
  // import { VMoney } from 'v-money'
  import { required } from 'vuelidate/lib/validators'
  import '@/components/common/SelectCommon/SelectCommon'
  import './CharterUploadsForm.styl'
  import moment from 'moment'

  export default {
    name: 'CharterUploadsForm',
    data: () => ({
      loading: false,
      aircraft: null,
      base: '',
      departureReRouting: '',
      arrivalReRouting: '',
      departuresCount: [
        {
          value: null,
        },
      ],
      departuresLimit: 5,
      landingCount: [
        {
          value: null,
        },
      ],
      landingLimit: 5,
      dateFormCount: [
        {
          value: null, // as `''` results in Vue warning
        },
      ],
      datesLimit: 5,
      pricePerHour: '',
      minAcceptCost: '',
      // money: {
      //   decimal: '.',
      //   thousands: ',',
      //   prefix: '$ ',
      //   precision: 2,
      //   masked: true
      // },
      departureItems: [],
      landingItems: [],
      focusedPotentialLocation: null,
      showDropdown: false,
      isNotReadyToSubmit: false,
      isDetail: false,
    }),
    // directives: { money: VMoney },
    validations: {
      base: { required },
      pricePerHour: { required },
      minAcceptCost: { required },
      maxSeats: { required },
      minSeats: { required },
      departureReRouting: {required},
      arrivalReRouting: {required},
    },
    computed: {
      fleet () {
        return this.$store.state.fleet.list
      },
    },
    async created () {
      // quick and dirty check
      await this.$store.dispatch('getAircrafts')
      const routeItems = this.$route.path.split('/')
      if (
        routeItems[routeItems.length - 1] !== 'charter-uploads' &&
        routeItems[routeItems.length - 1] !== 'new'
      ) {
        this.isDetail = true;

        try {
          const { data } = await aircraftsAPI.getAvailability(routeItems[routeItems.length - 1]);

          this.aircraft = { ...data }

          this.departureReRouting = data.departureReroutingRadius
          this.arrivalReRouting = data.arrivalReroutingRadius
          this.departuresCount = data.departureLocations.map(
            (item, index) => {
              return {
                value: item.displayName,
                info: {
                  id: item.locationTreeId,
                },
              }
            },
          )
          this.landingCount = data.arrivalLocations.map(
            (item, index) => {
              return {
                value: item.displayName,
                info: {
                  id: item.locationTreeId,
                },
              }
            },
          )
          this.dateFormCount = data.periods.map(
            (item, index) => {
              return {
                value: [moment(new Date(item.from)), moment(new Date(item.to))],
              }
            },
          )

          if (data.pricePerHour % 1 !== 0) {
            this.pricePerHour = `$ ${data.pricePerHour}`
          } else {
            this.pricePerHour = `$ ${data.pricePerHour.toFixed(0)}`
          }
          if (data.minimumAcceptablePricePerTrip % 1 !== 0) {
            this.minAcceptCost = `$ ${data.minimumAcceptablePricePerTrip}`
          } else {
            this.minAcceptCost = `$ ${data.minimumAcceptablePricePerTrip.toFixed(0)}`
          }
        } catch (e) {
        console.log(e)
        }
      } else { // is not availability detail
        if (this.fleet.length > 0) {
          this.aircraft = this.fleet[0]
        } else {
          const result = await this.$store.dispatch('getAircrafts')
          if (result) this.aircraft = this.fleet[0]
        }
      }
    },
    mounted () {
      this.$root.fields.changeRadio('.js-radio')
    },
    methods: {
      async searchLocations (locationType, query) {
        const that = this

        if (this.timeout != null) {
          clearTimeout(this.timeout)
        }

        this.timeout = setTimeout(async function () {
          this.timeout = null

          try {
            const { data } = await locationsAPI.searchAllLocations({ query })

            if (locationType === 'departure') {
              that.departureItems = data
            } else {
              that.landingItems = data
            }
          } catch (e) {
            console.loo(e)
          }
        }, 600)
      },
      setFocusedPotentialLocation (index) {
        this.focusedPotentialLocation = index;
      },
      selectPotentialLocation (locationType, item) {
        const i = this.focusedPotentialLocation;

        if (locationType === 'departure') {
          this.departuresCount[i].value = item.name;
          this.departuresCount[i].info = item;

          this.departureItems = [];
        } else {
          this.landingCount[i].value = item.name;
          this.landingCount[i].info = item;

          this.landingItems = [];
        }
      },
      selectChange (value) {
        const aircraft = this.fleet.find(a => a.tailNumber === value);

        if (!aircraft) {
          this.aircraft = null;
          return;
        }

        if (this.aircraft.availabilityId) {
          this.aircraft = {...aircraft, availabilityId: this.aircraft.availabilityId }
          return
        }

        this.aircraft = aircraft;
      },
      selectContainer () {
        return this.$refs.form.querySelector('.js-select-uploads')
      },
      getPickerContainer (trigger) {
        return this.$refs.form
      },
      removeField (id, type) {
        if (type === 'departure') {
          this.departuresCount = this.departuresCount.filter((item, index) => index !== id)
        } else if (type === 'landing') {
          this.landingCount = this.landingCount.filter((item, index) => index !== id)
        } else {
          this.dateFormCount = this.dateFormCount.filter((item, index) => index !== id)
        }
      },
      addFields (type) {
        if (type === 'departure' && this.departuresCount.length !== this.departuresLimit) {
          this.departuresCount.push({ value: null })
        } else if (type === 'landing' && this.landingCount.length !== this.landingLimit) {
          this.landingCount.push({ value: null })
        } else {
          this.dateFormCount.push({ value: null })
        }
      },
      isReadyToSubmit () {
        return this.aircraft &&
          this.pricePerHour !== '' &&
          this.minAcceptCost !== '' &&
          /^[0-9]+$/.test(this.departureReRouting) &&
          /^[0-9]+$/.test(this.arrivalReRouting) &&
          (this.departuresCount.length > 0 && Object.hasOwn(this.departuresCount[0], 'info')) &&
          (this.landingCount.length > 0 && Object.hasOwn(this.landingCount[0], 'info')) &&
          (this.dateFormCount.length > 0 && this.dateFormCount[0].value)
      },
      prepareLocationsDto (locations) {
        return locations.map(l => ({locationTreeId: l.info.id}))
      },
      preparePeriodsDto (periods) {
        return periods.map(p => {
          if (p.value !== '' || p.value !== null) return {from: p.value[0].format(), to: p.value[1].format()}
        })
      },
      async submitHandler () {
        if (!this.isReadyToSubmit()) {
          console.log('Invalid fields.')
          this.isNotReadyToSubmit = true;

          return false
        }

        this.isNotReadyToSubmit = false;
        this.loading = true

        const departureLocations = this.prepareLocationsDto(this.departuresCount);
        const arrivalLocations = this.prepareLocationsDto(this.landingCount);

        const periods = this.preparePeriodsDto(this.dateFormCount);

        const pricePerHour = this.pricePerHour.replace('$ ', '');
        const minAcceptCost = this.minAcceptCost.replace('$ ', '');
        const payload = {
          aircraftId: this.aircraft.aircraftId,
          aircraftTailNumber: this.aircraft.tailNumber,
          // pricePerHour: this.pricePerHour.replace(/[^0-9.-]+/g, ''),
          pricePerHour: Number(pricePerHour),
          // minimumAcceptablePrice: this.minAcceptCost.replace(/[^0-9.-]+/g, ''),
          minimumAcceptablePrice: Number(minAcceptCost),
          sellCharterSeat: true,
          departureReroutingRadius: this.departureReRouting,
          arrivalReroutingRadius: this.arrivalReRouting,
          departureLocations,
          arrivalLocations,
          periods,
        }
        if (this.aircraft.availabilityId) {
          payload.aircraftAvailabilityId = this.aircraft.availabilityId;
        }

        try {
          await aircraftsAPI.addAircraftAvailability(payload)
        } catch (e) {
          console.log(e)
        }

        this.loading = false

        this.$router.push('/provider-dashboard/charter-uploads');
      },
      async deleteAvailability () {
        this.loading = true

        try {
          await aircraftsAPI.removeAircraftAvailability({ availabilityId: this.aircraft.availabilityId })

          this.$router.push('/provider-dashboard/charter-uploads')
        } catch (e) {
          console.log(e)
        }

        this.loading = false
      },
    },
    components: {
      SvgIcon,
      Spinner,
    },
  }
</script>
